import React from "react";
import LayoutComponent from "../../components/layout/Layout";
import { NotifyUser } from "../../context/ManageUsersContext";
import ManageSchoolsComp from "../../components/contents/manage-comps/ManageSchoolsComp";
const ManageSchools = () => {
  return (
    <NotifyUser>
      <LayoutComponent>
        <h1>Manage Schools</h1>
        <ManageSchoolsComp />
      </LayoutComponent>
    </NotifyUser>
  );
};

export default ManageSchools;
