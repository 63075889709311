import React from "react";
import {
  DashboardOutlined,
  PlusOutlined,
  UserOutlined,
  CreditCardOutlined,
  TeamOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useLocation, Link } from "react-router-dom";

const breadcrumbNameMap = {
  "/dashboard": { title: "Dashboard", icon: <DashboardOutlined /> },
  "/addService": { title: "Add Service", icon: <PlusOutlined /> },
  "/manageService": { title: "Manage Service", icon: <FileOutlined /> },
  "/manageUser": { title: "Manage Passenger", icon: <UserOutlined /> },
  "/addDriver": { title: "Add Driver", icon: <UserOutlined /> },
  "/addStaff": { title: "Add Staff", icon: <TeamOutlined /> },
  "/passengerPayment": {
    title: "Passenger Payment",
    icon: <CreditCardOutlined />,
  },
  "/500": { title: "Server Error", icon: <FileOutlined /> },
  "/404": { title: "Not Found", icon: <FileOutlined /> },
};

const BreadcrumbComp = () => {
  const location = useLocation();
  const pathSnippets = location.pathname
    .split("/")
    .filter((i) => i && i !== "app"); // Filter out "app"

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const breadcrumb = breadcrumbNameMap[url];

    return breadcrumb
      ? {
          key: url,
          title: (
            <Link to={url}>
              {breadcrumb.icon}
              <span>{breadcrumb.title}</span>
            </Link>
          ),
        }
      : null;
  });

  return (
    <Breadcrumb
      style={{ margin: "16px 0", paddingLeft: "3rem" }}
      items={breadcrumbItems.filter(Boolean)} // Filter out null items
    />
  );
};

export default BreadcrumbComp;
