import React, { useState, useMemo } from "react";
import { Button, Input, Row, Col, Select } from "antd";
import { EditOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import { convertToDisplayDate } from "../../../utils/helper";

const excludedCols = [
  "id",
  "School ID",
  "School Name",
  "Phone",
  "Email",
  "Established Year",
  "Field",
  "Address",
  "Location",
  "Created At",
  "Updated At",
  "Created By",
];

const generateColumns = (data, setOpenManageModal) => {
  if (!data || data.length === 0) return [];

  const commonColumns = [
    {
      title: "School Info",
      key: "info",
      render: (text, record) => (
        <>
          <div className="tableSubCols heading">{record["School Name"]}</div>
          <div className="tableSubCols details">
            School ID: {record["School ID"]}
          </div>
          <div className="tableSubCols details">Phone: {record["Phone"]}</div>
          <div className="tableSubCols details">Field: {record["Field"]}</div>
          <div className="tableSubCols ">ID: {record["id"]}</div>
        </>
      ),
    },
    {
      title: "Contact",
      key: "contact",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">Email: {record["Email"]}</div>
          <div className="tableSubCols details">Phone: {record["Phone"]}</div>
        </>
      ),
    },
    {
      title: "Established",
      key: "established",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">
            Established Year:{" "}
            {convertToDisplayDate(record["Established Year"] || new Date())}
          </div>
        </>
      ),
    },
    {
      title: "Created",
      key: "created",
      render: (text, record) => (
        <>
          <div className="tableSubCols heading">
            {convertToDisplayDate(record["Created"] || new Date())}
          </div>
          <div className="tableSubCols details">
            Updated: {convertToDisplayDate(record["Updated"] || new Date())}
          </div>
          <div className="tableSubCols details">
            Created by: {record["Created By"]}
          </div>
        </>
      ),
    },
  ];

  const sampleItem = data[0];
  const keys = Object.keys(sampleItem).filter(
    (key) => !excludedCols.includes(key)
  );

  const dynamicColumns = keys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    key,
    dataIndex: key,
  }));

  dynamicColumns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        <Button
          type="link"
          onClick={() => {
            setOpenManageModal({ modalStatus: true, data: record });
          }}
        >
          <SettingOutlined />
        </Button>
      </span>
    ),
  });

  return [...commonColumns, ...dynamicColumns];
};

const SchoolTable = ({
  schoolData = [],
  fetchSchoolLoading,
  setOpenManageModal,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("School Name");

  const customColumns = useMemo(
    () => generateColumns(schoolData, setOpenManageModal),
    [schoolData]
  );

  const filteredData = useMemo(() => {
    if (!schoolData) return [];
    return schoolData.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [schoolData, searchText, selectedColumn]);

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const columnOptions = excludedCols.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div id="SchoolTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
            aria-label="Search"
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
            aria-label="Select Column"
          />
        </Col>
      </Row>
      <DynamicTable
        data={filteredData}
        loading={fetchSchoolLoading}
        customColumns={customColumns}
        scroll={{ x: 800 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default SchoolTable;
