import React, { useState } from "react";
import { Layout, Button, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";

import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";

const { Header } = Layout;

export default function HeaderComp() {
  const navigate = useNavigate();

  const handleConfirm = () => {
    localStorage.setItem("accessToken", "");
    navigate("/login");
  };
  return (
    <Header
      className="site-layout-background"
      style={{
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      <Popconfirm
        title="Are you sure you want to logout?"
        onConfirm={handleConfirm}
        okText="Yes"
        cancelText="No"
      >
        <Button type="link" icon={<LogoutOutlined />} className="logout-btn" />
      </Popconfirm>
    </Header>
  );
}
