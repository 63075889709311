import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { AccAuthProvider } from "./context/UserAccountAuth";
import AppRoutes from "./routes/AppRoutes";
import WebRoutes from "./routes/WebRoutes";
import { ApiComp } from "./services/api";
import AuthHandler from "./components/auth-handler/AuthHandler";
import useFetch from "./hooks/useFetch";
import PathTracker from "./components/pathTracker/PathTracker";

function App() {
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [authRes, setAuthRes] = useState(null);

  const { get: getAuth } = useFetch("auth/authenticate", {
    setLoading: setAuthLoading,
    setError: setAuthError,
    setData: setAuthRes,
  });

  useEffect(() => {
    getAuth();
  }, []);

  return (
    <Router>
      <ApiComp />
      <PathTracker />
      <ThemeProvider>
        <AccAuthProvider>
          <AuthHandler authLoading={authLoading} authRes={authRes} />
          <Routes>
            <Route path="/*" element={<WebRoutes />} />
            <Route path="/app/*" element={<AppRoutes />} />
          </Routes>
        </AccAuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
