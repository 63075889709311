import React from "react";
import { Spin } from "antd";
import styles from "./Loader.module.css"; // Import the CSS Module

const Loader = ({ size = "large", tip = "Loading...", className = "" }) => {
  return (
    <div className={`${styles.loaderContainer} ${className}`}>
      <Spin size={size} tip={tip}></Spin>
    </div>
  );
};

export default Loader;
