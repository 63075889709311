import { createContext, useContext, useState } from "react";

// Contexts for each service action

export const NotifyUserContext = createContext();

// Provider for Notifying user
export const NotifyUser = ({ children }) => {
  const [notifyUserToServiceDataObj, setNotifyUserToServiceDataObj] =
    useState(null);

  return (
    <NotifyUserContext.Provider
      value={{
        notifyUserToServiceDataObj,
        setNotifyUserToServiceDataObj,
      }}
    >
      {children}
    </NotifyUserContext.Provider>
  );
};

export const useNotifyUser = () => {
  return useContext(NotifyUserContext);
};
