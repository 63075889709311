import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function PathTracker() {
  const location = useLocation();
  useEffect(() => {
    console.log("path", location.pathname);

    if (location.pathname) localStorage.setItem("lastUrl", location.pathname);
  }, [location]);
  return <></>;
}
