import React from "react";
import PropTypes from "prop-types";
import { Table, Button, Popconfirm } from "antd";

const DynamicTable = ({
  data = [{}],
  tableActions,
  loading,
  customColumns,
  scroll,
}) => {
  // Generate columns dynamically from data keys if customColumns is not provided
  const generateColumns = (data) => {
    if (data?.length === 0) return [{}];
    const keys = Object.keys(data ? data[0] : [{}]);
    const columns = keys.map((key) => ({
      title: key.charAt(0)?.toUpperCase() + key.slice(1),
      dataIndex: key,
      key: key,
    }));

    // Add Actions column
    columns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          {tableActions?.map((tableAction, index) => {
            const actionKey = `${tableAction.label}-${index}`; // Ensure unique key
            return tableAction.confirm ? (
              <Popconfirm
                key={actionKey}
                title={tableAction.confirmMessage}
                onConfirm={() => tableAction.onClick(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger={tableAction.danger}>
                  {tableAction.label}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                key={actionKey}
                type="link"
                danger={tableAction.danger}
                onClick={() => tableAction.onClick(record)}
              >
                {tableAction.label}
              </Button>
            );
          })}
        </span>
      ),
    });

    return columns;
  };

  const columns = customColumns || generateColumns(data);

  return (
    <Table
      className="custom-scrollbar"
      columns={columns}
      dataSource={data}
      rowKey="id" // Ensure 'id' is unique
      loading={loading}
      scroll={{
        x: scroll?.x,
        y: 440,
      }}
    />
  );
};

DynamicTable.propTypes = {
  data: PropTypes.array.isRequired,
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
      confirm: PropTypes.bool,
      confirmMessage: PropTypes.string,
      danger: PropTypes.bool,
    })
  ).isRequired,
  customColumns: PropTypes.array,
  scroll: PropTypes.object, // Add scroll prop type
};

export default DynamicTable;
