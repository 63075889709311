import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  PlusOutlined,
  DashboardOutlined,
  UserOutlined,
  ShopOutlined,
  CarOutlined,
  TeamOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { FaSchool } from "react-icons/fa";

const { Sider } = Layout;
const { SubMenu } = Menu; // Import SubMenu

export default function PromoterSiderComp() {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current location
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1000);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 1000;
      setIsSmallScreen(smallScreen);
      setCollapsed(smallScreen);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = (e) => {
    const { key } = e;
    navigate(key);
  };

  const menuItems = [
    { key: "/app/dashboard", icon: <DashboardOutlined />, label: "Dashboard" },
    { key: "/app/addCompanyByPromo", icon: <FaSchool />, label: "Add Company" },
  ];

  return (
    <Layout className="custom-sider">
      <Sider
        collapsible={isSmallScreen}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        collapsedWidth={0}
        breakpoint="md"
      >
        <div className="logo">
          TOFI
          <span style={{ marginLeft: 5 }} className="text-primary">
            SYSTEMS
          </span>
        </div>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname]} // Set selected key based on current path
          onClick={handleMenuClick}
          items={menuItems}
          theme="dark"
        />
      </Sider>
    </Layout>
  );
}
