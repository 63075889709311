import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkAndNavigate } from "../utils/helper";

const baseUrl = window.location.origin;
let accessToken;
const api = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Refresh access token function
const refreshAccessToken = (accessToken) => {
  if (accessToken) localStorage.setItem("accessToken", accessToken);
};

// Custom hook to handle navigation
export const useAxiosInterceptors = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401) {
          const errorMessage = error.response.data.message;

          // Check if the error is due to an expired access token
          if (errorMessage === "Token required.") {
            localStorage.setItem("accessToken", "");
            checkAndNavigate(location, navigate);
          } else if (
            errorMessage === "Token expired." &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;

            try {
              const { data } = await axios.get("/auth/refresh-token", {
                withCredentials: true,
                headers: { Authorization: `Bearer ${accessToken}` },
              });

              refreshAccessToken(data.accessToken);

              api.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${data.accessToken}`;

              return api(originalRequest);
            } catch (refreshError) {
              console.error("Refresh token error:", refreshError);
              checkAndNavigate(location, navigate);

              return Promise.reject(refreshError);
            }
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      // Eject the interceptor when the component unmounts
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]);
};

// Example component using the custom hook
export const ApiComp = () => {
  useAxiosInterceptors();
  return <></>;
};

export const postApi = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getApi = async (endpoint, params = {}) => {
  try {
    const query = new URLSearchParams(params).toString();
    const response = await api.get(`${endpoint}?${query}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const deleteApi = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const patchApi = async (endpoint, value) => {
  try {
    const response = await api.patch(endpoint, value);
    return response.data;
  } catch (error) {
    throw error;
  }
};
