import React from "react";
import { Card, Form, Input } from "antd";

export default function ManageSchoolForm({ form, data }) {
  return (
    <Card title={`${data?.["School Name"]}`}>
      <Form
        form={form}
        name="school_database"
        layout="vertical"
        style={{ width: "100%", margin: "0 auto", padding: "16px" }}
      >
        <Form.Item
          label="School ID"
          name="schId"
          rules={[{ required: true, message: "school id not provided" }]}
          initialValue={data?.["School ID"]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Database Name"
          name="dbName"
          rules={[{ required: true, message: "enter the database name" }]}
        >
          <Input placeholder="Enter database name" />
        </Form.Item>

        <Form.Item
          label="Database Password"
          name="dbPass"
          rules={[{ required: true, message: "enter the database password" }]}
        >
          <Input.Password placeholder="Enter database password" />
        </Form.Item>

        <Form.Item
          label="Database User"
          name="dbUser"
          rules={[{ required: true, message: "enter the database user" }]}
        >
          <Input placeholder="Enter database user" />
        </Form.Item>

        <Form.Item
          label="Database Host"
          name="dbHost"
          rules={[{ required: true, message: "enter the database host" }]}
        >
          <Input placeholder="Enter database host" />
        </Form.Item>
      </Form>
    </Card>
  );
}
