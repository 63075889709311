// AppRoutes.js
import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
import ErrorRoutes from "./ErrorRoutes";
import ManageSchools from "../pages/manage/ManageSchools";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const AddCompany = lazy(() => import("../pages/registration/Company"));
const AddStaff = lazy(() => import("../pages/registration/Staff"));
const AddCompanyByPromo = lazy(() =>
  import("../pages/registration/CompanyByPromo")
);
const CompanyPayment = lazy(() => import("../pages/payment/CompanyPayment"));
const UserPayment = lazy(() => import("../pages/payment/UserPayment"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/addCompany" element={<AddCompany />} />
        <Route path="/addCompanyByPromo" element={<AddCompanyByPromo />} />

        {/* Staffs */}
        <Route path="/addStaff" element={<AddStaff />} />

        {/* Payment */}
        <Route path="/companyPayment" element={<CompanyPayment />} />
        <Route path="/userPayment" element={<UserPayment />} />

        {/* School */}
        <Route path="/manageSchool" element={<ManageSchools />} />

        {/* fallback route */}
        <Route path="*" element={<ErrorRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
