import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import SchoolTable from "../../tables/schools-table/SchoolTable";
import { Form, message, Modal } from "antd";
import ManageSchoolForm from "../../forms/manage-forms/ManageSchoolForm";
import usePost from "../../../hooks/usePost";

export default function ManageSchoolsComp() {
  const [form] = Form.useForm();
  const [fetchSchoolsLoading, setFetchSchoolsLoading] = useState(false);
  const [fetchSchoolsError, setFetchSchoolsError] = useState(null);
  const [fetchSchoolsRes, setFetchSchoolsRes] = useState(null);

  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createRes, setCreateRes] = useState(null);

  const [openManageModal, setOpenManageModal] = useState({
    modalStatus: false,
    data: {},
  });

  const [schoolData, setSchoolData] = useState([]);

  // Fetch schools
  const { get: fetchSchools, reset: resetFetchSchools } = useFetch(
    "/api/manageSchools",
    {
      setLoading: setFetchSchoolsLoading,
      setError: setFetchSchoolsError,
      setData: setFetchSchoolsRes,
    }
  );

  const { post: create, reset: resetCreate } = usePost(
    "/api/manageSchools/setDbConfigs",
    {
      setLoading: setCreateLoading,
      setError: setCreateError,
      setData: setCreateRes,
    }
  );

  const submitSchoolSetup = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      await create(values);
    } catch (errors) {
      errors?.errorFields?.forEach((error) => {
        console.log(error);
        message.error(error.errors[0]);
      });
    }
  };

  useEffect(() => {
    fetchSchools();
  }, []);
  useEffect(() => {
    if (typeof fetchSchoolsRes === "object") setSchoolData(fetchSchoolsRes);
  }, [fetchSchoolsRes]);

  useEffect(() => {
    console.log(createRes);
    if (createRes?.message) message[createRes?.status](createRes?.message);

    if (createRes?.status === "success") {
      setOpenManageModal({
        modalStatus: false,
        data: {},
      });
      form.resetFields();
    }
    fetchSchools();
    resetCreate();
  }, [createRes]);

  useEffect(() => {
    console.log(createError);
    if (createError?.message)
      return message[createError?.status || "error"](createError?.message);
    if (createError) message[createError?.status || "error"](createError);
    resetCreate();
  }, [createError]);

  return (
    <>
      <SchoolTable
        schoolData={schoolData}
        fetchSchoolLoading={fetchSchoolsLoading}
        setOpenManageModal={setOpenManageModal}
      />
      <Modal
        open={openManageModal?.modalStatus}
        title="Manage School"
        okText="Set"
        cancelText="Cancel"
        onCancel={() => setOpenManageModal({ modalStatus: false, data: {} })}
        onOk={submitSchoolSetup}
        loading={createLoading}
      >
        <ManageSchoolForm form={form} data={openManageModal?.data} />
      </Modal>
    </>
  );
}
