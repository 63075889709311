import React, { createContext, useState, useContext } from "react";

// Create the Theme Context
const ThemeContext = createContext();

// Create a custom hook to use the Theme Context
export const useTheme = () => useContext(ThemeContext);

// Create a ThemeProvider component
export const ThemeProvider = ({ children }) => {
  // State to hold the current theme
  const [theme, setTheme] = useState("light");

  // Toggle between light and dark themes
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Define theme values
  const themeValues = {
    theme,
    toggleTheme,
  };

  return (
    <ThemeContext.Provider value={themeValues}>
      {children}
    </ThemeContext.Provider>
  );
};
