import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAndNavigate } from "../../utils/helper";

const AuthHandler = ({ authLoading, authRes }) => {
  const navigate = useNavigate();

  const location = useLocation();

  // Get the auth from the server
  useEffect(() => {
    if (authRes?.authenticated) {
      checkAndNavigate(location, navigate);
    }
  }, [authRes]);
  if (authLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }
};

export default AuthHandler;
